import React, { Fragment, useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import Bucket from '../components/layout/bucket';
import { SuperButton } from '../components/layout/buttons';
import { SignupForm } from '../components/signup';
import { SmallBlueArrow, SmallGreenArrow } from '../components/layout/arrows';
import BarChart from '../components/pulse/barChart';
import DoughnutWrapper from '../components/pulse/doughnutWrapper';
import Question from '../components/pulse/question';
import QuestionHeader from '../components/pulse/questionHeader';
import PulseHeader from '../components/pulse/pulseHeader';
import SfIllustrationWhite from '../images/core/sf-illustration-white.svg';
import mail from '../images/icons/mail.svg';
import next from '../images/icons/next.svg';
import map from '../images/icons/maps-pin.svg';
import mayor from '../images/icons/mayor.svg';
import HomeListingWrapper from '../components/homeListingWrapper';
import ShowIfPageScrolled from '../components/showIfPageScrolled';
import Carousel from '../components/layout/carousel';
import useIsPageScrolled from '../hooks/useIsPageScrolled';

import { Oct24Housing, Sept24Mayor as PulseSection } from '../components/pulse/snippets';

const ElectionHero = ({ data }) => {
  const hero = getImage(data.hero.childImageSharp);
  const heroElection = getImage(data.heroElection.childImageSharp);
  return (
    <section>
      <div className="relative flex flex-col-reverse overflow-hidden -mt-px lg:min-h-[650px] lg:flex-col lg:mt-0">
        <Link to="/voter-guide/" className={`relative inset-0 lg:absolute pointer-events-none`}>
          <div className="absolute z-10 -top-0.5 left-0 w-full h-20 bg-gradient-to-b from-[#DAC9BD] to-transparent lg:hidden"></div>
          <GatsbyImage
            image={heroElection}
            loading="eager"
            alt="A view of San Francisco from Dolores Park"
            className="hidden h-full z-0 min-h-[250px] -mt-0.5 sm:mt-0 lg:block"
          />
          <GatsbyImage
            image={hero}
            loading="eager"
            alt="A view of San Francisco from Dolores Park"
            className={`z-0 h-full min-h-[250px] -mt-0.5 sm:mt-0 lg:hidden`}
          />
        </Link>
        <div
          className={`relative w-full flex flex-1 mx-auto pt-3 px-5 bg-gradient-to-b from-brand-blue-3 to-[#DAC9BD] lg:pt-0 lg:px-0 lg:to-[transparent_55%]`}
        >
          <Link to="/voter-guide/" className="absolute z-0 inset-0"></Link>
          <Link
            to="/voter-guide/"
            className="hero-top z-10 relative text-center bg-white m-auto w-full max-w-max px-6 py-12 lg:p-0 lg:bg-transparent lg:mt-24"
          >
            <h1 className="text-4xl font-black text-brand-blue-4 tracking-tight mb-2 lg:text-white lg:text-7xl">
              Read the #1 Voter Guide
            </h1>
            <p className="text-lg text-gray-700 leading-relaxed mb-6 lg:text-white lg:text-2xl">
              GrowSF is trusted by hundreds of thousands of San Franciscans
            </p>
            <div className="block max-w-max mx-auto px-5 py-6 capitalize font-medium rounded-sm text-lg text-white transition-all hover:scale-105 bg-gradient-to-r from-brand-blue-5 via-brand-blue-5 to-brand-blue-4 bg-size-200 bg-pos-0 hover:bg-pos-100 focus:bg-none focus:bg-brand-blue-4 focus:scale-100 lg:text-xl">
              Click here to read the GrowSF Voter Guide
              <img className="hidden ml-2 lg:inline" src={next} height={12} width={12} alt="next" />
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

const ImpactHero = ({ data }) => {
  return (
    <section
      className="bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(/homepage/hero_election.jpg)`, backgroundPosition: 'bottom' }}
    >
      <Link
        className="w-full flex flex-1 mx-auto pt-3 bg-gradient-to-b from-brand-blue-3 to-[transparent_55%] lg:pt-0 lg:px-0 pb-24 lg:pb-24"
        to="/impact-report-november-2024/"
      >
        <div className="hero-top text-center m-auto w-full lg:max-w-3xl px-6 py-12 lg:p-0 bg-transparent lg:mt-24">
          <h1 className="text-5xl sm:text-6xl lg:text-7xl font-black tracking-tight max-w-md sm:max-w-xl lg:max-w-3xl mx-auto mb-2 text-white ">
            Common sense won big in November
          </h1>
          <p className="text-lg leading-relaxed mb-6 text-white lg:text-2xl max-w-md text-balance lg:max-w-3xl mx-auto">
            For the first time in years, the Mayor, Board of Supervisors, and Board of Education are
            all aligned
          </p>
          <div className="text-balance block max-w-max mx-auto px-5 py-6 capitalize font-medium rounded-sm text-lg text-white transition-all hover:scale-105 bg-gradient-to-r from-brand-blue-5 via-brand-blue-5 to-brand-blue-4 bg-size-200 bg-pos-0 hover:bg-pos-100 focus:bg-none focus:bg-brand-blue-4 focus:scale-100 lg:text-xl">
            Click here to read GrowSF's November 2024 Impact Report{' '}
            <img className="ml-2 inline" src={next} height={12} width={12} alt="next" />
          </div>
        </div>
      </Link>
    </section>
  );
};

const TalentHero = ({ data }) => {
  return (
    <section
      className="bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(/homepage/hero_election.jpg)`, backgroundPosition: 'bottom' }}
    >
      <Link
        className="w-full flex flex-1 mx-auto pt-3 bg-gradient-to-b from-brand-blue-3 to-[transparent_55%] lg:pt-0 lg:px-0 pb-24 lg:pb-24"
        to="/talent/"
      >
        <div className="bg-slate-600 bg-opacity-50 hero-top text-center m-auto w-full lg:max-w-3xl px-6 py-12">
          <h1 className="text-5xl sm:text-6xl lg:text-7xl font-black tracking-tight max-w-md sm:max-w-xl lg:max-w-3xl mx-auto mb-2 text-white leading-relaxed text-balance">
            San Francisco needs you.
          </h1>
          <p className="text-xl leading-relaxed mb-6 text-slate-100 lg:text-2xl max-w-md text-balance lg:max-w-3xl mx-auto">
            Join a commission, work in City Hall, or run for office.
          </p>
          <div className="text-balance block max-w-max mx-auto px-5 py-6 capitalize font-medium rounded-xl  text-lg text-white transition-all hover:scale-105 bg-gradient-to-r from-brand-blue-5 via-brand-blue-5 to-brand-blue-4 bg-size-200 bg-pos-0 hover:bg-pos-100 focus:bg-none focus:bg-brand-blue-4 focus:scale-100 lg:text-xl">
            Learn more & join GrowSF Talent{' '}
            <img className="ml-2 inline" src={next} height={12} width={12} alt="next" />
          </div>
        </div>
      </Link>
    </section>
  );
};

const DefaultHero = ({ data }) => {
  const hero = getImage(data.hero.childImageSharp);
  return (
    <section>
      <div className="relative flex flex-col-reverse overflow-hidden -mt-px lg:min-h-[650px] lg:flex-col lg:mt-0">
        <div className={`relative inset-0 lg:absolute`}>
          <div className="absolute z-10 -top-0.5 left-0 w-full h-20 bg-gradient-to-b from-[#DAC9BD] to-transparent lg:hidden"></div>
          <GatsbyImage
            image={hero}
            loading="eager"
            alt="A view of San Francisco from Dolores Park"
            className={`z-0 h-full min-h-[250px] -mt-0.5 sm:mt-0`}
          />
        </div>
        <div
          className={`relative w-full flex flex-1 mx-auto pt-3 px-5 bg-gradient-to-b from-brand-blue-3 to-[#DAC9BD] lg:pt-0 lg:px-0 lg:bg-none lg:max-w-7xl'`}
        >
          <div className="hero-mask z-10 relative mx-auto w-full h-max sm:max-w-lg bg-white px-4 py-6 lg:px-12 lg:-mt-0.5 lg:-ml-0.5 lg:pb-12">
            <div className="px-1 text-center lg:text-left">
              <h1 className="text-3xl sm:text-4xl lg:text-6xl font-black text-gray-900 tracking-tight">
                It's time to fix San&nbsp;Francisco
              </h1>
              <p className="text-base text-gray-700 leading-relaxed mb-6">
                Outcomes matter more than ideology, and our officials have failed at the basics:
                public safety, homelessness, housing, public education, and more. GrowSF pursues
                common sense solutions to create a San Francisco that works for everyone.
              </p>
              <div className="z-10 relative">
                <h2 className="text-sm font-bold text-brand-blue-5 mb-1.5">
                  Sign up to receive our voter guides and important SF news
                </h2>
                <SignupForm formStyle="home" formId="_hero" />
              </div>
            </div>
            <img
              className="z-0 absolute bottom-28 right-0 lg:bottom-12"
              height={114}
              width={114}
              src={mail}
              alt="mail"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
const Index = ({ data, location }) => {
  const isElection = false;
  const isSpecial = true;
  const isPageScrolled = useIsPageScrolled();
  const WrongWithHousing = getImage(data.WrongWithHousing.childImageSharp);
  const Prop47 = getImage(data.Prop47.childImageSharp);
  const DumpDean = getImage(data.DumpDean.childImageSharp);
  const FentanylEmergency = getImage(data.FentanylEmergency.childImageSharp);
  const RecallChesaBoudin = getImage(data.RecallChesaBoudin.childImageSharp);
  const SchoolBoardRecallBus = getImage(data.SchoolBoardRecallBus.childImageSharp);
  const Dorsey = getImage(data.Dorsey.childImageSharp);
  const Engardio = getImage(data.Engardio.childImageSharp);
  const Jenkins = getImage(data.Jenkins.childImageSharp);
  const DoloresPark = getImage(data.DoloresPark.childImageSharp);
  const JumpingOnBeach = getImage(data.JumpingOnBeach.childImageSharp);
  const GGB = getImage(data.GGB.childImageSharp);
  const OutdoorDining = getImage(data.OutdoorDining.childImageSharp);
  const SupervisorMap = getImage(data.SupervisorMap.childImageSharp);
  const PlaceForAll = getImage(data.PlaceForAll.childImageSharp);
  const RcvEducation = getImage(data.RcvEducation.childImageSharp);
  const transitPoll = getImage(data.transitPoll.childImageSharp);
  const buildingTowardNovember = getImage(data.buildingTowardNovember.childImageSharp);
  const dumpDeanSuccess = getImage(data.dumpDeanSuccess.childImageSharp);
  const impactReport = getImage(data.ImpactReport.childImageSharp);

  const LayoutWidth = 'mx-auto px-4 sm:max-w-md sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl';

  const Issue = ({ Title, Description }) => (
    <div className="mb-6 break-inside-avoid-column">
      <h2 className="text-xl font-bold text-gray-800">{Title}</h2>
      <p className="leading-normal text-gray-500">{Description}</p>
    </div>
  );

  const newsList = data.news.nodes.map((item) => ({
    title: item.frontmatter.title,
    description: item.frontmatter.description,
    href: item.slug,
    image: getImage(item.frontmatter.featured_image.childImageSharp),
  }));

  const blogList = [
    {
      title: 'Grassroots “Dump Dean” Campaign a Huge Success',
      description:
        "GrowSF's “Dump Dean” Campaign Was The Largest Grassroots Effort to Oppose an Incumbent Supervisor in SF History.",
      href: '/news/dump-dean-huge-success/',
      image: dumpDeanSuccess,
    },
    {
      title: 'Building Toward November',
      description:
        'Replacing our failed leadership is the only way to make SF a liveable place for families.',
      href: '/news/2024-06-building-toward-november/',
      image: buildingTowardNovember,
    },
    {
      title: 'Our new poll on SF transit and street safety',
      description: 'San Franciscans want to ride Muni, bike, and walk more – safely.',
      href: '/pulse/growsf-pulse-may-2024/',
      image: transitPoll,
    },
    {
      title: 'Ranked choice education key to November',
      description: 'What GrowSF is doing to help SF navigate our choices this fall.',
      href: '/research/2024-04-ranked-choice-voting-san-francisco/',
      image: RcvEducation,
    },
    {
      title: "What's wrong with housing in San Francisco?",
      description: 'A shortage of homes drives up prices and drives out families.',
      href: '/research/whats-wrong-with-housing-in-san-francisco/',
      image: WrongWithHousing,
    },
    {
      title: 'The impact of Prop 47 on crime in San Francisco',
      description:
        'In 2014, voters approved Proposition 47, The Safe Neighborhoods and Schools Act. But the marketing of this bill obscured the reality of its impacts.',
      href: '/research/prop-47/',
      image: Prop47,
    },
  ];
  const currentCampaigns = [
    {
      title: 'November 2024 Impact Report',
      description:
        'Common sense won big in November. For the first time in years, the Mayor, Board of Supervisors, and Board of Education are all aligned.',
      href: '/impact-report-november-2024/',
      image: impactReport,
    },
    {
      title: 'Fentanyl Emergency',
      description:
        "We support Mayor Lurie's fentanyl emergency declaration. Sign our petition to ensure the legislation passes!",
      href: '/issues/fentanyl-emergency',
      image: FentanylEmergency,
    },
    {
      title: 'Find your Supervisor',
      description: 'Use our tool to find and contact your City Supervisor.',
      href: '/sf-district-supervisor-map/',
      image: SupervisorMap,
    },
  ];

  const pastCampaigns = [
    {
      title: 'Dump Dean Preston',
      description:
        'GrowSF led the largest grassroots effort in SF history to unseat an incumbent supervisor, raising over $300k from 300 donors.',
      href: '/dumpdean',
      image: DumpDean,
    },
    {
      title: 'Support Joel Engardio for District 4 Supervisor',
      description:
        'Engardio made history by being the first person to beat an incumbent since SF moved to district supervisors.',
      href: '/voter-guide/san-francisco-voter-guide-november-2022-election/#supervisor-district-4',
      image: Engardio,
      checked: true,
    },
    {
      title: 'Support Matt Dorsey for District 6 Supervisor',
      description:
        'Matt Dorsey is a strong voice for public safety, personal responsibility, and for holding drug dealers accountable.',
      href: '/voter-guide/san-francisco-voter-guide-november-2022-election/#supervisor-district-6',
      image: Dorsey,
      checked: true,
    },
    {
      title: 'Support Brooke Jenkins for District Attorney',
      description:
        'Jenkins has followed through on her promises to prioritize the prosecution of anti-Asian hate crimes and dealers of deadly drugs. Whether or not you voted for the recall of Chesa Boudin, we think you should vote for Brooke Jenkins.',
      href: '/voter-guide/san-francisco-voter-guide-november-2022-election/#district-attorney',
      image: Jenkins,
      checked: true,
    },
    {
      title: 'Recall Chesa Boudin',
      description:
        'Boudin put his own ideology ahead of the needs of SF residents. We need a DA who takes public safety seriously, and GrowSF helped lead this recall effort.',
      href: '/voter-guide/san-francisco-voter-guide-2022-district-attorney-recall-election/',
      image: RecallChesaBoudin,
      checked: true,
    },
    {
      title: 'School Board Recall',
      description:
        'GrowSF was proud to stand with frustrated parents to demand the recall of San Francisco’s incompetent school board.',
      href: '/issues/school-board-recall',
      image: SchoolBoardRecallBus,
      checked: true,
    },
  ];

  const issuesContent = [
    {
      title: 'Build more housing',
      description:
        'From young families seeking homeownership to seniors, everyone needs an affordable place to live.',
    },
    {
      title: 'Improve public safety',
      description:
        'Increase police staffing, put drug dealers in jail, and provide treatment programs for users.',
    },
    {
      title: 'Reduce homelessness',
      description:
        'Build shelters of all types: navigation centers, permanent supportive housing, and innovative solutions.',
    },
    {
      title: 'Streamline small business regulations',
      description: 'Celebrate new ideas and reward economic innovation to provide jobs.',
    },
    {
      title: 'Improve public schools',
      description: 'Increase the quality of education and focus on outcomes, not ideology.',
    },
    {
      title: 'Make our transit system fast, safe, and reliable',
      description:
        'From subway tunnels to bus lanes, build infrastructure projects that efficiently get people where they need to go.',
    },
    /*     {
      title: 'Reimagine how we use public space.',
      description:
        'Create an oceanside park at the former Great Highway, make Golden Gate Park’s JFK Drive permanently car-free, and expand outdoor dining.',
    }, */
    {
      title: 'Ensure City Hall is free from corruption',
      description: 'Audit every city program and only pay for what works.',
    },
  ];

  return (
    <Layout
      headerOptions={{
        borderless: true,
        blueMobile: true,
        blueDesktop: isElection || isSpecial,
      }}
    >
      <SEO
        title="GrowSF: Highly-researched Voter Guides for Delivering Results in San Francisco"
        description="GrowSF is a community of residents who believe San Francisco can be a world class city for everyone."
      />
      {/* Hero */}
      {isElection ? (
        <ElectionHero data={data} />
      ) : isSpecial ? (
        <TalentHero data={data} />
      ) : (
        <DefaultHero data={data} />
      )}
      {/* End Hero */}
      {/* Begin find your supervisor */}
      {/*       <section>
        <div className="grid md:grid-cols-12 md:gap-4 mx-auto px-5 pt-8 sm:px-6 lg:pt-16 lg:px-8 lg:max-w-7xl">
          <Link
            to="/sf-district-supervisor-map/"
            className='col-span-full flex flex-col p-5 justify-between gap-5 rounded isolate overflow-hidden bg-[url("../images/homepage/callout_map_mobile.png")] lg:bg-[url("../images/homepage/callout_map.png")] bg-cover bg-center lg:flex-row lg:p-12'
          >
            <div className="flex flex-col justify-center items-center gap-1.5 lg:gap-10 lg:flex-row">
              <img
                className="h-20 w-20 my-auto lg:h-24 lg:w-24"
                height={80}
                width={80}
                src={map}
                alt="mail"
              />
              <p className="text-center text-2xl font-black text-brand-blue-5 tracking-tight capitalize max-w-xl sm:text-4xl lg:text-left lg:text-5xl lg:tracking-wide">
                Find Your Supervisor with our District Map
              </p>
            </div>
            <button className="whitespace-nowrap h-max flex justify-center items-center w-max my-auto mx-auto px-5 py-4 uppercase tracking-widest font-bold rounded-sm text-xs text-white transition-all hover:scale-105 bg-gradient-to-r from-brand-blue-5 via-brand-blue-5 to-brand-blue-4 bg-size-200 bg-pos-0 hover:bg-pos-100 focus:bg-none focus:bg-brand-blue-4 focus:scale-100 break-inside-avoid lg:mx-0">
              find your supervisor
            </button>
          </Link>
        </div>
      </section> */}
      {/* End find your supervisor */}
      <div className={`flex flex-col w-full`}>
        {/* Begin 'What we're working toward */}
        <section>
          <div className="grid md:grid-cols-12 md:gap-4 mx-auto px-5 pt-8 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl  mb-10">
            <div className="sm:col-span-4">
              <h1 className="text-3xl sm:text-4xl lg:text-6xl font-black text-gray-900 tracking-tight mb-4">
                What we're working towards.
              </h1>
              <SuperButton className="mt-2 sm:mt-10 mb-2" color="blue" size="sm" to="/issues">
                Visit our issues page to learn more
              </SuperButton>
            </div>
            <div className="sm:col-span-8 sm:columns-2">
              {issuesContent.map((issue, index) => (
                <Issue key={index} Title={issue.title} Description={issue.description} />
              ))}
            </div>
          </div>
        </section>
        {/* End 'What we're working toward */}

        {/* Begin Secondary Email Signup */}
        {/*       <section>
        <div className="mx-4 sm:mx-auto bg-brand-blue-1 p-5 sm:p-10 relative overflow-clip mt-10 mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="z-10 relative md:grid md:grid-cols-2 md:gap-24 items-center">
            <div>
              <h2 className="text-lg leading-relaxed md:text-xl md:leading-relaxed font-bold text-gray-900">
                Sign up for GrowSF's weekly roundup of important SF news
              </h2>
            </div>
            <div>
              <p className="mt-3 text-sm font-bold text-gray-700 uppercase">
                Sign up for our weekly newsletter
              </p>
              <SignupForm formStyle="home" formId="_signup" />
            </div>
          </div>
          <img
            className="z-0 scale-75 absolute -bottom-20 -right-20"
            src={SfIllustrationWhite}
            alt="Line art illustration of San Francisco"
          />
        </div>
      </section> */}
        {/* End Secondary Email Signup */}
        {/* Start Homepage Mission */}
        {/* <section>
        <div className="relative mt-6 sm:mt-36 overflow-x-clip">
          <div className="sm:absolute relative inset-0 bottom-20">
            <div className="z-10 absolute inset-0 bg-gradient-to-t from-brand-blue-3 to-transparent" />
            <GatsbyImage
              image={JumpingOnBeach}
              alt="Jumping on beach near Lands End"
              className="z-0 h-full w-full sm:object-cover"
            />
          </div>
          <div className={`mx-auto px-4 -mt-20 -mb-20 sm:px-6 sm:pt-96 md:px-10 lg:max-w-7xl`}>
            <SmallGreenArrow>
              <div className="absolute z-0 -mx-5 bg-brand-blue-3 inset-x-0 inset-y-20 sm:hidden"></div>
              <div className="relative z-[9] lg:grid lg:grid-cols-12 lg:gap-16 bg-brand-blue-5 p-5 sm:p-10 lg:p-20">
                <div className="lg:col-span-5">
                  <h1 className="text-3xl md:text-6xl font-black text-gray-50">Our Mission</h1>
                  <p className="text-2xl text-brand-green-3 leading-relaxed mt-4">
                    GrowSF wants San Francisco to be safe, clean, affordable, and vibrant.
                  </p>
                  <SuperButton className="mt-6 mb-2" color="green" size="sm" to="/mission">
                    Visit our mission page
                  </SuperButton>
                  <p className="text-gray-400 sm:w-56">
                    for a full look at our goals, strategy, and long-term vision.
                  </p>
                </div>
                <div className="lg:col-span-7">
                  <p className="text-gray-50 mb-6 text-lg mt-8 sm:mt-0">
                    GrowSF prioritizes outcomes over ideologies. Our local leaders are not focused
                    on actual progress, and residents feel under-informed about local issues and
                    elections. We want to change that.
                  </p>
                  <p className="text-gray-50 mb-6 text-lg">
                    Our elected officials have failed us on the basics: housing, transit, schools,
                    and public safety. We want common-sense solutions to the problems that regular
                    people face. It’s time to fix San Francisco.
                  </p>
                  <p className="text-gray-50 text-lg">
                    GrowSF supports solutions that embrace San Francisco values. We celebrate art
                    and culture, value compassion, seek sustainability, and practice social justice.
                    GrowSF supports a city government that is efficiently managed, responsive to
                    quality of life issues, and above all follows common sense.
                  </p>
                </div>
              </div>
            </SmallGreenArrow>
          </div>
        </div>
      </section> */}
        {/* End Homepage Mission */}
        {/* Begin News from GrowSF Blog */}
        <section>
          <HomeListingWrapper
            className={'pb-7 lg:pb-0'}
            theme={'yellow'}
            name={'GrowSF News'}
            title={"What's In The News"}
            link={{ text: 'See all', href: '/news' }}
            list={newsList}
          ></HomeListingWrapper>
        </section>
        {/* End News from GrowSF Blog */}
        {/* Start  projects */}
        <section>
          <HomeListingWrapper
            className={'pb-7 lg:pb-10'}
            theme={'default'}
            name={'Our Current campaigns'}
            title={'How You Can Get Involved'}
            link={{ text: 'See all', href: '/campaigns' }}
            itemsPerRow={3}
            list={currentCampaigns}
            mobileCarousel={true}
            loadCarousel={isPageScrolled}
            breakpoints={{
              // when window width is >= 640px -- sm
              640: {
                slidesPerView: 2.1,
              },
            }}
          ></HomeListingWrapper>
        </section>
        {/* End  projects */}
        {/* Start  projects */}
        <section>
          <HomeListingWrapper
            className={'pb-7 lg:pb-10'}
            theme={'yellow'}
            name={'Our Past Campaigns'}
            title={'What We Have Accomplished'}
            link={{ text: 'See all', href: '/past-campaigns' }}
            itemsPerRow={3}
            list={pastCampaigns}
            mobileCarousel={true}
            loadCarousel={isPageScrolled}
            breakpoints={{
              // when window width is >= 640px -- sm
              640: {
                slidesPerView: 2.1,
              },
            }}
          ></HomeListingWrapper>
        </section>
        {/* End  projects */}
        {/* Begin Check Out Our Latest Pulse Poll */}
        <section>
          <div className="px-5 pt-8 sm:pt-16 sm:px-6 lg:px-8">
            <Link
              to="/pulse/growsf-pulse-october-2024/"
              className="flex flex-col gap-0 max-w-7xl mx-auto p-5 [border-image:linear-gradient(to_top_right,#FEDC6B,#64D09C)_30] border-transparent border-[5px] lg:border-8 xl:gap-12 lg:flex-row lg:p-12"
            >
              <div className="flex flex-col my-auto w-full gap-4 lg:max-w-lg">
                <h1 className="text-center mx-auto text-2xl font-black text-brand-blue-5 tracking-tight capitalize max-w-xl sm:text-4xl lg:text-left lg:text-5xl lg:mx-0">
                  San Franciscans overwhelmingly support building more homes.
                </h1>
                <span className="text-center text-sm font-light lg:max-w-lg text-brand-blue-5 mb-1 sm:mb-4 lg:text-left lg:pr-2 xl:pr-16">
                  San Franciscans overwhelmingly support building more housing and making it easier
                  to modify existing buildings. Yet the city is held hostage by NIMBY elected
                  officials who overwhelmingly block the kinds of reforms people want. Online
                  petitions aren’t enough—the only way to deliver the change our city wants is to
                  win elections.
                </span>
                <button className="hidden justify-center items-center w-max px-5 py-3.5 font-bold rounded-sm text-xs text-white transition-all hover:scale-105 bg-gradient-to-r from-brand-green-4 via-brand-green-4 to-brand-blue-4 bg-size-200 bg-pos-0 hover:bg-pos-100 focus:bg-none focus:bg-brand-blue-4 focus:scale-100 break-inside-avoid tracking-widest lg:flex">
                  See full results
                </button>
              </div>
              <div className="rounded h-max my-auto -mx-5 lg:mx-0">
                <ShowIfPageScrolled>
                  <Oct24Housing node={null} />
                </ShowIfPageScrolled>
              </div>
              <button className="flex justify-center items-center w-full px-5 py-3.5 font-bold rounded-sm text-xs text-white transition-all hover:scale-105 bg-gradient-to-r from-brand-green-4 via-brand-green-4 to-brand-blue-4 bg-size-200 bg-pos-0 hover:bg-pos-100 focus:bg-none focus:bg-brand-blue-4 focus:scale-100 break-inside-avoid tracking-widest sm:w-max lg:hidden">
                See full results
              </button>
            </Link>
          </div>
        </section>
        {/* End Check Out Our Latest Pulse Poll */}
      </div>
      {/* Begin News from GrowSF Blog */}
      <section>
        <HomeListingWrapper
          className={'pb-7 lg:pb-0'}
          theme={'default'}
          name={'GrowSF News'}
          title={'What You Need To Know'}
          link={{ text: 'See all', href: '/news' }}
          list={blogList}
        ></HomeListingWrapper>
      </section>
      {/* End News from GrowSF Blog */}
    </Layout>
  );
};
export default Index;

export const query = graphql`
  query {
    news: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { post_type: { in: ["news", "research"] }, hide: { ne: true } } }
      limit: 6
    ) {
      nodes {
        id
        slug
        frontmatter {
          title
          description
          featured_image {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                formats: [WEBP, AUTO]
                width: 400
                sizes: "400"
              )
            }
          }
        }
      }
    }
    research: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { post_type: { in: ["research"] }, hide: { ne: true } } }
      limit: 6
    ) {
      nodes {
        id
        slug
        frontmatter {
          title
          description
          featured_image {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                layout: CONSTRAINED
                formats: [WEBP, AUTO]
                width: 400
                sizes: "400"
              )
            }
          }
        }
      }
    }
    dumpDeanSuccess: file(
      relativePath: { eq: "news/dump-dean-huge-success/dump-dean-success.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    buildingTowardNovember: file(
      relativePath: { eq: "news/2024-06-building-toward-november/why_growsf_matters.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    transitPoll: file(
      relativePath: { eq: "pulse/growsf-pulse-may-2024/growsf-pulse-may-2024.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    RcvEducation: file(
      relativePath: { eq: "research/2024-04-ranked-choice-voting-san-francisco/rcv-simulator.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    WrongWithHousing: file(
      relativePath: {
        eq: "research/whats-wrong-with-housing-in-san-francisco/whats-wrong-with-housing-sf.png"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    Prop47: file(
      relativePath: { eq: "research/prop-47/prop-47-larceny-theft-san-francisco.jpeg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    ImpactReport: file(relativePath: { eq: "impact-report/november-2024-impact-report.png" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }

    DumpDean: file(relativePath: { eq: "dumpdean/dump-dean-2.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    FentanylEmergency: file(
      relativePath: { eq: "issues/fentanyl-emergency/fentanyl-emergency.png" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    Dorsey: file(
      relativePath: {
        eq: "voter-guide/san-francisco-voter-guide-november-2022-election/dorsey-endorsement.jpg"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    Engardio: file(
      relativePath: {
        eq: "voter-guide/san-francisco-voter-guide-november-2022-election/engardio-endorsement.jpg"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          quality: 100
        )
      }
    }
    Jenkins: file(
      relativePath: {
        eq: "voter-guide/san-francisco-voter-guide-november-2022-election/jenkins.jpg"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          quality: 100
        )
      }
    }
    RecallChesaBoudin: file(
      relativePath: {
        eq: "voter-guide/san-francisco-voter-guide-2022-district-attorney-recall-election/boudin-endorsement.png"
      }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          quality: 100
        )
      }
    }
    SchoolBoardRecallBus: file(
      relativePath: { eq: "issues/school-board-recall/school-bus-recall.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          quality: 100
        )
      }
    }
    DoloresPark: file(relativePath: { eq: "core/dolores-park.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    hero: file(relativePath: { eq: "homepage/hero.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          formats: [WEBP, AUTO]
          breakpoints: [400, 800, 1200, 1400]
        )
      }
    }
    heroElection: file(relativePath: { eq: "homepage/hero_election.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          formats: [WEBP, AUTO]
          breakpoints: [400, 800, 1200, 1400]
        )
      }
    }
    JumpingOnBeach: file(relativePath: { eq: "homepage/jumping-over-water.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    GGB: file(relativePath: { eq: "core/ggb.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    SupervisorMap: file(relativePath: { eq: "sf-supervisor-map/sf-supervisor-district-map.png" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    OutdoorDining: file(
      relativePath: { eq: "issues/make-shared-spaces-permanent/outdoor-dining.jpg" }
    ) {
      id
      childImageSharp {
        gatsbyImageData
      }
    }
    PlaceForAll: file(relativePath: { eq: "issues/end-street-homelessness/tinyhomes.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
  }
`;
